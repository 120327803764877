
import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: 'es',
    server : 'https://dmb-continental.dev.doubledot.es/'
  },
  getters : {
    getServer : state => state.server,
  },  
  mutations: {
    null(){

    }
  },
  actions: {
    requestEntries: ({ commit }, category) => {
      let data = {
        filterPack: true,
        filters: [
          {
            name: "visible",
            search: 1,
          },
          {
            name: "categories.slug",
            search: category
          }
        ],
        columns: [
          {
            name: "categories",
            onlyWith: true,
          },
        ],
      };

      return new Promise((resolve) => {
        axios.post(`/api/entries`, data).then(response => {
          resolve(response.data);
          commit('null');
        });
      });
    },
    requestEntrie: ({ commit },id) => {
      return new Promise((resolve) => {
        axios.get(`/api/entries${(id) ? '/' + id : ''}`).then(response => {
          resolve(response.data);
          commit('null');
        });
      });
    },
    requestCategories: ({ commit }) => {
      return new Promise((resolve) => {
        axios.post(`/api/categories`).then(response => {
          resolve(response.data);
          commit('null');
        });
      });
    },
  },
  modules: {
  }
})
